import React from 'react'

import { Grid } from '@mui/material'
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  EditActionsProps,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TabbedForm,
  TextInput,
  TopToolbar,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { useParams } from 'react-router-dom'

import {
  LXEditPeriodicRates,
  LXEditPeriodicRatesHandle,
} from '../../components/LXEditPeriodicRates'
import { NoDeleteEditToolbar } from '../../components/NoDeleteEditToolbar'
import { CMS_LIST } from '../../helpers/constants'
import { LXField } from './../../components/LXField'
import { LXImageInput } from './../../components/LXImageInput'
import { ListHelper } from './../../helpers/List.helper'
import styles from './Beneficiary.module.scss'

export const BeneficiaryEdit = (): JSX.Element => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const params = useParams()

  const editRateRef = React.useRef<LXEditPeriodicRatesHandle>(null)

  const onSuccess = React.useCallback(async () => {
    await editRateRef.current?.save()
    refresh()
    redirect('show', '/beneficiaries', params.id)
  }, [refresh, redirect, params.id])

  return (
    <Edit
      className={styles.edit}
      actions={<BeneficiaryEditActions />}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess,
      }}
      redirect="show"
    >
      <TabbedForm toolbar={<NoDeleteEditToolbar />}>
        <FormTab label={translate('pages.beneficiaries._tabs.1')} contentClassName={styles.tab}>
          <BeneficiaryEditInformations />
        </FormTab>
        <FormTab
          label={translate('pages.beneficiaries._tabs.3')}
          path="periodic-rates"
          contentClassName={styles.tab}
        >
          <LXEditPeriodicRates
            ref={editRateRef}
            rateable="beneficiary"
            rateableId={params.id as string}
          />
        </FormTab>
        <FormTab
          label={translate('pages.beneficiaries.statementSettings.title')}
          path="statement-settings"
          contentClassName={styles.tab}
        >
          <BeneficiaryEditStatementSettings />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

const BeneficiaryEditStatementSettings: React.FC = () => {
  const translate = useTranslate()

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item container direction="column" className={styles.edit} lg={9}>
        <BooleanInput
          source="statementSettings.introduction"
          label={translate('pages.beneficiaries.statementSettings.introduction')}
        />
        <BooleanInput
          source="statementSettings.globalInformations"
          label={translate('pages.beneficiaries.statementSettings.globalInformation')}
        />
        <BooleanInput
          validate={(value, values) => {
            if (values.statementSettings.globalInformations && value) {
              return translate('pages.beneficiaries.statementSettings.firstPageLXGreetingsError')
            }

            return null
          }}
          source="statementSettings.firstPageLXGreetings"
          label={translate('pages.beneficiaries.statementSettings.firstPageLXGreetings')}
        />
        <BooleanInput
          source="statementSettings.productTypes"
          label={translate('pages.beneficiaries.statementSettings.productTypes')}
        />
        <BooleanInput
          source="statementSettings.products"
          label={translate('pages.beneficiaries.statementSettings.products')}
        />
        <BooleanInput
          source="statementSettings.artworks"
          label={translate('pages.beneficiaries.statementSettings.artworks')}
        />
        <BooleanInput
          source="statementSettings.shorts"
          label={translate('pages.beneficiaries.statementSettings.shorts')}
        />
        <BooleanInput
          source="statementSettings.monthlyComparison"
          label={translate('pages.beneficiaries.statementSettings.monthlyComparison')}
        />
        <BooleanInput
          source="statementSettings.yearlyComparison"
          label={translate('pages.beneficiaries.statementSettings.yearlyComparison')}
        />
      </Grid>
    </Grid>
  )
}

const BeneficiaryEditInformations = () => {
  const translate = useTranslate()

  return (
    <Grid container direction="row" spacing={3}>
      <LXImageInput
        source="logoBase64"
        label={translate('_generics.logo')}
        placeholder={translate('pages.beneficiaries.imagePlaceholder')}
      />
      <Grid item container direction="row" className={styles.edit} lg={9} spacing={2}>
        <LXField source="name" label={translate('_generics.name')} elementType={TextInput} />
        <LXField>
          <SelectInput
            source="cms"
            fullWidth
            choices={CMS_LIST}
            defaultValue="logicomix"
            label={translate('pages.youtubeChannels.cms')}
          />
        </LXField>
        <LXField
          source="contactEmail"
          label={translate('pages.beneficiaries.contactEmail')}
          elementType={TextInput}
        />
        <LXField source="siret" elementType={TextInput} />
        <LXField source="tvaNumber" elementType={TextInput} />
        <LXField>
          <ReferenceInput
            source="beneficiaryTypeId"
            reference="beneficiaryTypes"
            fullWidth
            sort={ListHelper.DEFAULT_SORT}
          >
            <AutocompleteInput
              optionText="name"
              label={translate('pages.beneficiaries.beneficiaryType')}
            />
          </ReferenceInput>
        </LXField>
      </Grid>
      <Grid item container direction="row" className={styles.edit} lg={12} spacing={2}>
        <Grid item container direction="row" className={styles.edit} lg={2} />
        <LXField>
          <SelectInput
            source="dearPoliteForm"
            fullWidth
            choices={[
              {
                id: translate('pages.beneficiaries.dearPolite.options.single_male'),
                name: translate('pages.beneficiaries.dearPolite.options.single_male'),
              },
              {
                id: translate('pages.beneficiaries.dearPolite.options.single_female'),
                name: translate('pages.beneficiaries.dearPolite.options.single_female'),
              },
              {
                id: translate('pages.beneficiaries.dearPolite.options.plural_male'),
                name: translate('pages.beneficiaries.dearPolite.options.plural_male'),
              },
              {
                id: translate('pages.beneficiaries.dearPolite.options.plural_female'),
                name: translate('pages.beneficiaries.dearPolite.options.plural_female'),
              },
            ]}
            label={translate('pages.beneficiaries.dearPolite.title')}
          />
        </LXField>
        <LXField
          source="contactFirstName"
          label={translate('pages.beneficiaries.contactFirstName')}
          elementType={TextInput}
        />
        <LXField
          source="contactName"
          label={translate('pages.beneficiaries.contactName')}
          elementType={TextInput}
        />
      </Grid>
      <Grid item container direction="row" className={styles.edit} lg={12} spacing={2}>
        <Grid item container direction="row" className={styles.edit} lg={2} />
        <LXField source="address" label={translate('_generics.address')} elementType={TextInput} />
        <LXField
          source="address2"
          label={translate('_generics.address2')}
          elementType={TextInput}
        />
      </Grid>
      <Grid item container direction="row" className={styles.edit} lg={10}>
        <Grid item container direction="row" className={styles.edit} lg={2} />
        <BooleanInput
          source="isAutoCorrected"
          label={translate('pages.beneficiaries.isAutoCorrected')}
        />
        <LXField>
          <ReferenceInput
            source="defaultDatavizPresetId"
            reference="datavizPresets"
            fullWidth
            sort={ListHelper.DEFAULT_SORT}
          >
            <AutocompleteInput
              optionText="name"
              label={translate('pages.beneficiaries.defaultDatavizPreset')}
            />
          </ReferenceInput>
        </LXField>
        <Grid item container direction="row" className={styles.edit} lg={6}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.isAutoCorrected && (
                <>
                  <LXField size={12}>
                    <ReferenceInput
                      allowEmpty
                      className={styles.autocomplete}
                      source="defaultProductId"
                      filter={{ beneficiaryId: formData.id }}
                      reference="products"
                      sort={ListHelper.DEFAULT_SORT}
                    >
                      <AutocompleteInput
                        optionText="name"
                        className={styles.autocomplete}
                        helperText={false}
                        label={translate('pages.beneficiaries.defaultProductId')}
                      />
                    </ReferenceInput>
                  </LXField>
                  <LXField size={12}>
                    <ReferenceInput
                      className={styles.autocomplete}
                      source="defaultProductTypeId"
                      reference="productTypes"
                      sort={ListHelper.DEFAULT_SORT}
                    >
                      <AutocompleteInput
                        optionText="name"
                        className={styles.autocomplete}
                        helperText={false}
                        label={translate('pages.beneficiaries.defaultProductTypeId')}
                      />
                    </ReferenceInput>
                  </LXField>
                </>
              )
            }
          </FormDataConsumer>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={styles.edit} lg={12} spacing={2}>
        <Grid item container direction="row" className={styles.edit} lg={2} />
        <LXField
          source="mediaUploadGoogleDriveFolderId"
          label={translate('pages.beneficiaries.mediaUploadGoogleDriveFolderId')}
          elementType={TextInput}
        />
      </Grid>
    </Grid>
  )
}

const BeneficiaryEditActions = ({ data }: EditActionsProps) => (
  <TopToolbar>
    <ShowButton record={data} />
  </TopToolbar>
)
